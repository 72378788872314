'use client'

import { Cell, Image, Radio } from '@vinted/web-ui'
import { ChangeEvent, ReactNode, useState } from 'react'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { UserSuggestionModel } from 'types/models'

import InputDropdown, { InputDropdownRenderProps } from '../InputDropdown'
import List from '../List'
import UserImage from '../UserImage'

type Props = {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onSave: (user: UserSuggestionModel) => void
  title?: string
  users: Array<UserSuggestionModel>
  placeholder?: string
  selectedUser?: UserSuggestionModel | null
  preselectedUser?: UserSuggestionModel | null
  disableSearch?: boolean
  name: string
  validation?: ReactNode
}

const UserSelect = ({
  title,
  users,
  placeholder,
  selectedUser,
  onChange,
  onSave,
  name,
  validation,
  preselectedUser,
  disableSearch,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState(preselectedUser || selectedUser)
  const breakpoints = useBreakpoint()

  const handleUserSelect =
    (user: UserSuggestionModel, inputRenderProps: InputDropdownRenderProps) => () => {
      setSelectedValue(user)

      inputRenderProps.setIsSaveEnabled(true)

      if (breakpoints.phones) return

      inputRenderProps.closeDropdown()
      onSave(user)
    }

  function renderUser(user: UserSuggestionModel, inputRenderProps: InputDropdownRenderProps) {
    const isSelected = selectedValue?.id === user.id

    return (
      <Cell
        key={user.id}
        title={user.login}
        clickable
        onClick={handleUserSelect(user, inputRenderProps)}
        prefix={
          <UserImage
            src={user.thumbnail}
            size={Image.Size.Regular}
            styling={Image.Styling.Circle}
          />
        }
        suffix={
          <div data-testid={`user-select-${user.login}`}>
            <Radio
              name="user-select"
              checked={isSelected}
              onChange={() => undefined}
              aria={{
                'aria-labelledby': undefined,
              }}
            />
          </div>
        }
      />
    )
  }

  function renderUserList(inputRenderProps: InputDropdownRenderProps) {
    if (!users) return null

    return <List>{users.map(user => renderUser(user, inputRenderProps))}</List>
  }

  function handleSave() {
    if (!selectedValue) return

    onSave(selectedValue)
  }

  function handleCancel() {
    setSelectedValue(selectedUser)
  }

  return (
    <InputDropdown
      title={title}
      placeholder={placeholder}
      name={name}
      render={renderUserList}
      onChange={onChange}
      onSave={handleSave}
      onCancel={handleCancel}
      value={selectedUser?.login}
      readOnly={disableSearch}
      disabled={!!preselectedUser}
      validation={validation}
    />
  )
}

export default UserSelect
